import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import { phoneVerification, sendPhoneCode } from "../../../slices/auth";
import lifecycle from "react-pure-lifecycle";
import Countdown from "react-countdown";
import ReactInputVerificationCode from "react-input-verification-code";

import Form from "react-bootstrap/Form";

const methods = {
  componentDidMount(props) {},
};

const PhoneVerificationComponent = () => {
  const [show, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [sid, setPhoneSid] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [counter, setCounter] = useState(0);
  const [code, setCode] = useState("0");

  const currentUser = useSelector((state) => state.auth.user);
  const state = useLocation();

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isDirty, isValid },
  } = useForm();

  useEffect(() => {
    if (state.state != null && state.state !== undefined) {
      setPhoneNumber(state.state.user_phone_number);
      setPhoneSid(state.state.sid);
    } else if (
      currentUser !== null &&
      currentUser !== undefined &&
      currentUser !== {}
    ) {
      setPhoneNumber(currentUser.user_phone_number);
    }
  }, [currentUser, state]);

  const onErrors = (errors) => console.error(errors);

  const onFormSubmit = (formValue) => {
    var enteredCode = getValues();
    dispatch(
      phoneVerification({
        phone: phoneNumber,
        code: code,
        sid: sid,
      })
    )
      .unwrap()
      .then((value) => {
        if (value.status !== true) {
          setError("resend", { type: "custom", message: value.error });
        } else {
          if (currentUser !== undefined) {
            navigate("/resetpassword", { state: { code: code, sid: sid } });
          } else {
            navigate("/feeds");
          }
        }
      })
      .catch(() => {});
  };

  const onResendCode = () => {
    clearErrors("resend");
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 120000);
    setCounter(Date.now() + 120000);
    dispatch(sendPhoneCode(phoneNumber))
      .unwrap()
      .then((value) => {
        if (value.status !== true) {
          setError("resend", { type: "custom", message: value.error });
        } else {
          setShow(true);
        }
      })
      .catch(() => {});
  };
  return (
    <div
      className="container  d-flex align-items-md-center  flex-column justify-content-center mx-md-5"
      style={{ minHeight: "95vh" }}
    >
      <div className="auth-form-container pt-5 px-md-5 pb-3 col-md-12 d-flex flex-column mx-md-5">
        <h1 className="verification-title">Verify Your Phone Number</h1>

        <Form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
          <div className="pb-3 mx-3 ">
            <ReactInputVerificationCode
              value="      "
              onChange={(v) => {
                setCode(v.trim());
              }}
              length={6}
            />

            <div className="text-danger">
              {errors?.code && errors.code.message}
            </div>
          </div>
          <p className="note pb-5">
            We’ve sent an OTP code to
            <span className="linkspan">
              {" "}
              {phoneNumber !== undefined &&
                phoneNumber.phone_code + phoneNumber.phone_number}
              {phoneNumber === undefined && "your number"}{" "}
            </span>
            . Enter the 6-digit-code to verify your phone number.
          </p>
          <div className="  justify-content-center d-flex  align-items-md-center">
            <button
              className="note btn btn-link linkspan "
              disabled={disabled}
              style={{ paddingRight: "3px", paddingTop: "9px" }}
              onClick={() => onResendCode()}
            >
              Click here
            </button>
            <span className="note">to resend a new code.</span>{" "}
            {counter !== 0 && (
              <div>
                {"( "}
                <Countdown date={counter} daysInHours />
                {" ) "}
              </div>
            )}
          </div>
          {errors?.resend && (
            <div className="text-danger  py-1 px-1 mx-3" style={{}}>
              {errors.resend.message}
            </div>
          )}
          <div>
            <Button
              type="submit"
              disabled={code.replace(/\./g, "").length !== 6}
              className={"auth-submit-button mt-3  py-2 mx-3 "}
              onClick={() => clearErrors("resend")}
            >
              Verify
            </Button>
          </div>
        </Form>

        {show && (
          <div className="App">
            <div className="p-4 text-success">Code sent successfully</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default lifecycle(methods)(PhoneVerificationComponent);
