import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth";
import generalSlice from "../slices/general";
import postSlice from "../slices/post";
import uploadSlice from "../slices/uploadfile";
const reducer = {
  auth: authReducer,
  general: generalSlice,
  post:postSlice,
  upload:uploadSlice
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
