import { axiosInstance } from "../config/network";

const getposts = ({ offset, limit,type }) => {
  return axiosInstance({
    method: "GET",
    params: { limit: limit, offset: offset, type: type },
    url: "/post/feed/",
  })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
          posts: res.data.data.results,
          count: res.data.data.count,
        };
      } else {
        return { status: false, error: res.data.data.error };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data.error,
      };
    });
};

const pushPost = (post) => {
  return axiosInstance
    .post("/post/", post)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        console.log(res)
        return {
          status: true,
          post: res.data,
        };
      } else {
        return { status: false, error: res.data };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data,
      };
    });
};



const postService = {
  getposts,
  pushPost,
};

export default postService;
