import { axiosInstance } from "../config/network";

const login = (identity, password) => {
  return axiosInstance
    .post("/login/general/", { identity, password })

    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
          user: res.data.data,
          token: res.data.data.access,
        };
      } else {
        return { status: false, error: res.data.data.error };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data.error,
      };
    });
};

const forgetPasswordByEmail = (email) => {
  return axiosInstance
    .post("/password-reset/", { email })

    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
          email: email,
        };
      } else {
        return { status: false, error: res.data.data["email"] };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data["email"],
      };
    });
};

const forgetPasswordByMobile = (data) => {
  return axiosInstance
    .post("/password-reset/phone-number/", {
      phone_code: data.user_phone_number.phone_code,
      country_code: data.user_phone_number.country_code,
      phone_number: data.user_phone_number.phone_number,
    })

    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
          data: res.data.data,
          sid: res.data.data.sid,
        };
      } else {
        return { status: false, error: res.data.data };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data["error"],
      };
    });
};

const forgetPasswordEmailConfirmation = (email, code) => {
  return axiosInstance
    .post("/password-reset/validate-token/", {
      code: code,
    })

    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
        };
      } else {
        return { status: false, error: res.data.data.error };
      }
    })
    .catch((err) => {
      if (err.response.data.data.error !== undefined) {
        return { status: false, error: err.response.data.data.error };
      } else if (err.response.data.data.code !== undefined) {
        return { status: false, error: err.response.data.data.code };
      }
      if (err.response.data.status === 404) {
        return {
          status: false,
          error: "Invalid code",
        };
      }

      return {
        status: false,
        error: "Invalid code",
      };
    });
};

const emailVerification = (email, code) => {
  return axiosInstance
    .post("/user/email-verify/", { email: email, code: code })

    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
        };
      } else {
        return { status: false, error: res.data.data.error };
      }
    })
    .catch((err) => {
      if (err.response.data.data.error !== undefined) {
        return { status: false, error: err.response.data.data.error };
      } else if (err.response.data.data.code !== undefined) {
        return { status: false, error: err.response.data.data.code };
      }

      return {
        status: false,
        error: "something went wrong",
      };
    });
};

const resetPasswordPhoneVerification = (phone, sid) => {
  return axiosInstance
    .post("/user/verify-phone/", {
      phone_number: phone.phone.phone_code + "-" + phone.phone.phone_number,
      sid: phone.sid,
    })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
        };
      } else {
        return {
          status: false,
          error: "something went wrong",
        };
      }
    })
    .catch((err) => {
      if (err.response.data.data.error !== undefined) {
        return { status: false, error: err.response.data.data.error };
      } else if (err.response.data.data.code !== undefined) {
        return { status: false, error: err.response.data.data.code };
      }

      return {
        status: false,
        error: "something went wrong",
      };
    });
};

const phoneVerification = (phone, code) => {
  return axiosInstance
    .post("/user/verify-phone/", {
      phone_number: phone.phone.phone_code + "-" + phone.phone.phone_number,
      code: phone.code,
    })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
        };
      } else {
        return {
          status: false,
          error: "something went wrong",
        };
      }
    })
    .catch((err) => {
      if (err.response.data.data.code !== undefined) {
        return { status: false, error: err.response.data.data.code };
      } else if (err.response.data.data.error !== undefined) {
        return { status: false, error: err.response.data.data.error };
      }

      return {
        status: false,
        error: "something went wrong",
      };
    });
};

const sendPhoneCodeVerification = (phone) => {
  return axiosInstance
    .post("/user/re-send-verify-phone/", {
      phone_code: phone.phone_code,
      country_code: phone.country_code,
      phone_number: phone.phone_number,
    })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
          user: res.data.data,
          token: res.data.data.access,
        };
      } else {
        return { status: false, error: res.data.data.error };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data.error,
      };
    });
};

const sendEmailCodeVerification = (email) => {
  return axiosInstance
    .post("/user/email-verify/resend/", { email })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          status: true,
        };
      } else {
        return { status: false, error: res.data.data.error };
      }
    })
    .catch((err) => {
      return {
        status: false,
        error: err.response.data.data.error,
      };
    });
};
const signup = (formData) => {
  var user = JSON.parse(localStorage.getItem("registeruserstepone"));
  const uploadData = new FormData();
  uploadData.append("username", user.username);
  if (user.email !== undefined) uploadData.append("email", user.email);
  uploadData.append("dob", formData.date);
  uploadData.append("password", formData.password);

  if (
    user.phone_number !== undefined &&
    user.phone_number !== null &&
    user.phone_number !== ""
  ) {
    uploadData.append("phone_number", user.phone_number.split("-")[1]);
    uploadData.append("phone_code", user.user_phone_number.phone_code);
    uploadData.append("country_code", user.user_phone_number.country_code);
  }

  uploadData.append("gender", formData.gender);

  uploadData.append("display_name", user.display_name);
  if (
    formData.avatar !== null &&
    formData.avatar !== null &&
    formData.avatar !== {}
  )
    uploadData.append("avatar", formData.avatar);

  return axiosInstance
    .post("/signup/", uploadData)

    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      if (response.status === 201) {
        return {
          status: true,
          user: response.data.data,
          token: response.data.data.token,
          error: null,
        };
      } else {
        return { status: false, error: response.data.data };
      }
    })
    .catch((e) => {
      if (e.response.data["data"]["email"] != undefined) {
        return { status: false, error: e.response.data.data.email };
      } else if (e.response.data["data"]["error"] !== undefined) {
        if (typeof e.response.data["data"]["error"] == "string") {
          return { status: false, error: e.response.data.data.error };
        } else {
          return { status: false, error: e.response.data.data.error[0] };
        }
      } else if (e.response.data["data"]["username"] !== undefined) {
        return { status: false, error: "Username already exists" };
      }

      return { status: false, error: e.response.data.data };
    });
};
const accountcheck = (formData) => {
  return axiosInstance
    .post("/account/check/", {
      username: formData.username,
      phone_number: formData.phone_number,
      email: formData.email,
    })

    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("registeruserstepone", JSON.stringify(formData));
        return {
          status: true,
          user: formData,
          msg: response.data.data.status,
          error: response.data.data.message,
        };
      }

      return {
        status: false,
        user: formData,
        error: response.data.data.message,
      };
    })
    .catch((err) => {
      return { status: false, user: formData, error: err.response.data.data };
    });
};
const verifySocial = (socialType, secertKey) => {
  return axiosInstance
    .post("/social/" + socialType + "/", { access_token: secertKey })
    .then((res) => {
      if (res.status === 201 || res.status === 200) {
        localStorage.setItem("IsLoggedIn", true);
        localStorage.setItem("user", JSON.stringify(res.data.data));

        return {
          status: true,
          user: res.data.data,
        };
      } else return null;
    })
    .catch((err) => {
      return { status: false, error: err.response.data.data };
    });
};

const resetPassword = (password, code) => {
  return axiosInstance
    .post("/password-reset/confirm/", {
      password: password.password,
      code: password.code,
    })

    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        localStorage.setItem("user", JSON.stringify(res.data.data));
        localStorage.setItem("IsLoggedIn", true);

        return {
          status: true,
          user: res.data.data,
          token: res.data.data.access,
        };
      } else {
        return { status: false, error: res.data.data.error };
      }
    })
    .catch((err) => {
      if (err.response.data.data.error !== undefined) {
        return { status: false, error: err.response.data.data.error };
      } else if (err.response.data.data.code !== undefined) {
        return { status: false, error: err.response.data.data.code };
      }

      return {
        status: false,
        error: "something went wrong",
      };
    });
};

const authService = {
  login,
  emailVerification,
  phoneVerification,
  sendEmailCodeVerification,
  sendPhoneCodeVerification,
  signup,
  accountcheck,
  forgetPasswordByEmail,
  forgetPasswordEmailConfirmation,
  verifySocial,
  resetPassword,
  forgetPasswordByMobile,
  resetPasswordPhoneVerification,
};

export default authService;
