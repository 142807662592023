import React, { useState, useRef } from "react";

import AboutPhone from "../../../../../assets/images/AboutIPhone.png"; // Import using relative path
import TitleIcon from "../../../../../assets/images/title-icon.svg"; // Import using relative path

const About = () => {
  const [loading, setLoading] = useState(true);
  const urls = [AboutPhone];
  const counter = useRef(0);

  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= urls.length) {
      setLoading(false);
    }
  };
  return (
    <div className="container mt-5 ">
      <div className=" d-flex justify-content-start align-items-center">
        <img
          src={TitleIcon}
          alt=" SVG"
          style={{
            marginRight: "5px",
            width: "35px",
          }}
        />
        <span className="title">About Wyspp</span>
      </div>

      <div className="row">
        <div className="col-lg-5 col-sm-12 center-on-sm d-flex">
          <div className="col-lg-10 col-sm-12  d-flex center-on-sm mt-4">
            <div style={{ display: loading ? "block" : "none" }}>
              <div className="spinner-border mx-auto" role="status"></div>
            </div>
            <div 
            className="justify-content-end 
            align-items-center
            d-flex
            center-on-sm 
            pt-5" 
            style={{ display: loading ? "none" : "block" }}>
            <img
                key={AboutPhone}
                src={AboutPhone}
                onLoad={imageLoaded}
                className="imag-fluid about-img mx-auto"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-sm-12 mt-5">
          <h3 className="italic-title mt-5"> Wyspp</h3>
          <h1 className="small-italic-title"> / (wisp) /</h1>
          <p className="paragraph">
            It’s easy to say WYSPP [noun.] was born out of frustration. But, the
            underlying need to provide a voice to those interested in the
            preservation of free and unbiased journalism is more accurate. After
            watching countless news networks funded by agendas that serve a
            chosen few and not the greater good, we developed WYSPP. An
            opportunity for those to record history as everyone sees it.
            Objective views of events and newsworthy happenings all over the
            world.
          </p>

          <p className="paragraph pt-2">
            WYSPP [verb.] anything worthy of someone else’s time and interest.
            From local happenings, good or bad, to global events that impact
            millions of people. It’s your time to be a part of recorded history
            and to acknowledge its accuracy, increase or decrease its
            visibility, and act socially responsible. What will you WYSPP today?
          </p>
        </div>
      </div>
    </div>
    // <Grid
    //   container
    //   style={{
    //     color: "white",
    //     paddingLeft: "10%",
    //     paddingRight: "10%",
    //   }}
    // >
    //   <Title icon={TitleIcon} title={"About Wyspp"}></Title>
    //   <Grid xs={12} md={12} lg={12} container direction={"row"}>
    //     <Grid xs={12} md={6} lg={6} alignContent="center" alignItems={"center"}>
    //       <img
    //         src={AboutPhone}
    //         alt=" SVG"
    //         style={{
    //           marginRight: "5px",
    //           width: "68%",
    //           WebkitTransform: "rotate(-10deg)",
    //           MozTransform: "rotate(-10deg)",
    //           transform: "rotate(-10deg)",
    //         }}
    //       />
    //     </Grid>
    //     <Grid xs={12} md={6} lg={6} sx={{ paddingTop: "10%" }}>
    //       <div>
    //         <Typography
    //           style={{
    //             fontFamily: "Poppins",
    //             marginBottom: "10px",
    //             fontStyle: "italic",
    //             fontWeight: "600",
    //             fontSize: "30px",
    //             lineHeight: "48px",
    //           }}
    //         >
    //           Wyspp
    //         </Typography>
    //         <Typography
    //           style={{
    //             fontFamily: "Poppins",
    //             fontStyle: "italic",
    //             fontWeight: "400",
    //             marginBottom: "10px",

    //             fontSize: "18px",
    //           }}
    //         >
    //           / (wisp) /
    //         </Typography>
    //         <Typography
    //           style={{
    //             fontFamily: "Poppins",
    //             fontWeight: "300",
    //             fontSize: "18px",
    //             marginBottom: "10px",
    //           }}
    //         >
    //           It’s easy to say WYSPP [noun.] was born out of frustration. But,
    //           the underlying need to provide a voice to those interested in the
    //           preservation of free and unbiased journalism is more accurate.
    //           After watching countless news networks funded by agendas that
    //           serve a chosen few and not the greater good, we developed WYSPP.
    //           An opportunity for those to record history as everyone sees it.
    //           Objective views of events and newsworthy happenings all over the
    //           world.
    //         </Typography>

    //         <Typography
    //           style={{
    //             fontFamily: "Poppins",
    //             fontWeight: "300",
    //             fontSize: "18px",
    //           }}
    //         >
    //           WYSPP [verb.] anything worthy of someone else’s time and interest.
    //           From local happenings, good or bad, to global events that impact
    //           millions of people. It’s your time to be a part of recorded
    //           history and to acknowledge its accuracy, increase or decrease its
    //           visibility, and act socially responsible. What will you WYSPP
    //           today?
    //         </Typography>
    //       </div>
    //     </Grid>
    //   </Grid>
    // </Grid>
  );
};

export default About;
