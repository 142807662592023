import store from "../config/store";

import axios from "axios";
import { baseURL, requestTimeout } from "../config/constant";

import authApi from "../services/Auth";

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: requestTimeout,
  // headers: {
  //   accept: "application/json",
  //   'Content-Type': "application/json",
  // },
});


axiosInstance.interceptors.request.use((req) => {
  const token = store.getState().auth.token;
  // if (token) req.headers = { Authorization: `Bearer ${token}` };
  // req.headers = {
  //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjgxNzI1NzAwLCJqdGkiOiIwMWQxOTdjNDQ0MTE0OGU2YWVkZDQxZWUzYTgzMjVmNyIsInVzZXJfaWQiOiJ1c2VyQGV4YW1wbGUuY29tIn0.RLt2_leocxZyrUt3vTbhw-Jx448EgGds8-kaRlPyeIw`,
  // };
  return req;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes("/login") &&
      !originalRequest.url.includes("/refresh")
    ) {
      originalRequest._retry = true;

      const { refreshToken } = store.getState().auth;

      return (
        authApi
          .refresh(refreshToken)
          // eslint-disable-next-line consistent-return
          .then((response) => {
            if (response.status === 200) {
              const { access, refresh } = response.data.data;

              // 1) Save Tokens
              // store.dispatch({
              //   type: SET_TOKEN,
              //   payload: {
              //     token: access,
              //     refreshToken: refresh,
              //   },
              // });

              // 2) return originalRequest ob ject with Axios.
              return axiosInstance(originalRequest);
            }
          })
          .catch(() => {
            //  store.dispatch({ type: LOGOUT });
          })
      );
    }

    return Promise.reject(error);
  }
);
