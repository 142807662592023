import * as React from "react";
import Accordion from "react-bootstrap/Accordion";

export default function AllFAQsComponent() {
  var accordionArray = [
    {
        "id":1,
        "title": "What is WYSPP?",
        "desc": "WYSPP is a way to put journalism in your hands, the citizen's hands. Whether it be an event that impacts you and others locally, or globally, WYSPP is the platform to share it on. It's your time to be a part of recorded history and to acknowledge its accuracy, increase or decrease its visibility, and act socially responsible."
    },
    {
      "id":2,
      "title": "What is a Watch?",
        "desc": "A Watch is a citizen-reported event. A Wyspp can be posted by you, a neighbor, or someone across the globe. And if you're not the original poster, you'll have the chance to interact and verify its accuracy. But keep in mind, you'll only be able to interact if you're within a 10-mile radius of the location of the event."
    },
    {
      "id":3,
      "title": "What is Connect?",
        "desc": "Connect is your chance to find answers and come to a conclusion with your community and other WYSPP users across the globe. Translate cryptic or foreign text, identify unknown objects, and more."
    },
    {
      "id":4,
      "title": "What is Missing?",
        "desc": "Think of “Missing” as a hyper-localized lost and found for anything from items to pets to missing persons. It goes beyond your neighborhood, to any WYSPP user within a 50-mile radius. Avoiding the noise social media and other outlets inevitably encounter."
    },
    {
      "id":5,
      "title": "Is my mobile device supported?",
        "desc": "The WYSPP mobile app is supported on the following platforms:\niOS 11.0 or later (iPhone, iPod touch, and/or iPad)\nAndroid 2.3.x and 5.x.x"
    },
    {
      "id":6,
      "title": "How do I get the WYSPP app on my phone?",
        "desc": "Downloading WYSPP is quick and simple. Head to the Apple App Store or Google Play store to download and begin Wyspping now!"
    },
    {
      "id":7,
      "title": "Does WYSPP cost money?",
        "desc": "WYSPP is completely free! No hidden fees or in-app purchases here. We believe sharing knowledge and happenings in your community should be free and accessible to everyone!"
    },
    {
      "id":8,
      "title": "How do I sign up for a Wyspp account?",
        "desc": "After downloading the WYSPP Mobile App, you'll be prompted to create an account as soon as you launch the app. You will also be able to create an account through our web app."
    },
    {
      "id":9,
      "title": "Can I use WYSPP anonymously?",
        "desc": "Yes - your security is very important to us. WYSPP is a citizen journalism platform that encourages non-biased opinions and community teamwork. If at any time you publish a Wyspp either anonymously or not, you'll have the option to go back and change this preference later. However, staying anonymous is only available when posting a Watch. Your username will be shown when using Connect and Missing."
    },
    {
      "id":10,
      "title": "Why isn't my Watch, Connect, or Missing post showing up?",
        "desc": "If your Watch, Connect, or Missing post has either been removed or hasn't been published, you may be having trouble connecting to the WYSPP app, wifi, or a cellular network. If your connection is strong and your post has still not been published, it may not adhere to our community or safety guidelines."
    },
    {
      "id":11,
      "title": "Is the WYSPP mobile app secure?",
        "desc": "Yes! We will never share your email, phone number, login information or any other information with anyone - ever."
    },
    {
      "id":12,
      "title": "Where can I use the WYSPP app?",
        "desc": "Simple - anywhere there is Wifi or cellular connection. WYSPP is supported globally."
    },
    {
      "id":13,
      "title": "Can I see a Wyspp that has been published in another state, country, or region?",
        "desc": "Yes - you can see a Wyspp that has been published from anywhere in the world. However, you will not be able to interact unless you're within a 10-mile radius of the original Wyspp location. If you're interested in seeing a Wyspp from any particular location, you can simply follow that location and you'll be able to see that location's published Wyspps in your feed."
    },
    {
      "id":14,
      "title": "Do I need a WYSPP account to publish a Watch, Connect, and Missing post or to interact with others?",
        "desc": "Yes - in order to publish or interact on WYSPP, you'll need an account. You'll be able to view things others publish but won't be able to interact or publish for yourself without a WYSPP account."
    },
    {
      "id":15,
      "title": "Where is WYSPP located?",
        "desc": "The WYSPP headquarters are located in sunny Boca Raton, Florida."
    },
    {
      "id":16,
      "title": "How do I contact WYSPP if my question isn't answered here?",
        "desc": "If your question isn't answered in the FAQs, visit our Help & Support page and shoot us your question. We'll do our best to get back to you within 24-48 hours."
    }
]
  return (
    <div
      className="container"
      style={{
        marginBottom: "50px",
        paddingLeft: "5%",
      }}
    >
      <Accordion defaultActiveKey="0">
        {accordionArray.map((d) => (
          <Accordion.Item
            key={d.id}
            style={{
              marginTop: "5px",
              marginBottom: "15px",
              backgroundColor: "rgba(238, 241, 243, 0.1)",
              borderRadius: " 8px",
              border: "none",
            }}
            eventKey={d.id}
          >
            <Accordion.Header
              style={{
                //backgroundColor: "rgba(238, 241, 243, 0.1)",
                //color: "rgba(238, 241, 243, 0.1)",
                fontWeight: "400",
                fontSize: "25px",
                border: "none",
                borderRadius: " 8px",
              }}
            >
              {d.title}
            </Accordion.Header>
            <Accordion.Body
              style={{
                color: "white",
                fontWeight: "400",
                fontSize: "18px",
              }}
            >
              {d.desc}
              {d.id === 5 && (
                <ul>
                  <li>
                    iOS 11.0 or later (iPhone, iPod touch, and/or iPad) 
                  </li>
                  <li>and Android 5.x.x or later</li>
                </ul>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}
