import React, {useState,useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import ResponsiveAppBar from "../../../../../layouts/ui/Header";
import Footer from "../../../../../layouts/ui/Footer";
import { Outlet } from "react-router-dom";
const MainLayout = () => {

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  return (
    <div>
      <ResponsiveAppBar />
      <Outlet />
      <Footer />
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          &#8679;
        </button>
      )}
      <CookieConsent debug={false} location="bottom" cookieName="accept_cookie" style={{
    paddingRight:"7%",
    paddingLeft:"7%",
    textShadow: "2px 2px black",
  }} 
  buttonStyle={{
    background: "#2FB2FF",
    color: "white",
    borderRadius: " 8px",
    padding:"5px 10px 5px 10px",
  }}
  overlay>
  We use cookies to offer you a better browsing experience and to analyze site traffic. By continuing to use our website, you consent to the use of these cookies.
</CookieConsent>
    </div>
  );
};

export default MainLayout;
