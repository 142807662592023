import React, { useState } from "react";

import Laptop from "../../../../../assets/images/laptop.png"; // Import using relative path
import ModalVideo from "react-modal-video";
const VideoIntro = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="container  mt-5  " style={{}}>
      <div className=" row">
        <div className="d-flex align-items-center col-md-6 col-sm-12   pt-3  ">
          <div className="align-items-center  video-intro-text-con pt-3">
            <p className="body-text  pt-3 bottom-space">
              Get the scoop on WYSPP and why everyone who’s interested in
              reporting only the truth needs this app!
            </p>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <img
            src={Laptop}
            alt="Your SVG"
            role="button"
            className="fluid img-responsive"
            width="100%"
            onClick={() => setOpen(true)}
          />
        </div>

        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="MnUlkXjeJvU"
          onClose={() => setOpen(false)}
        />
      </div>
    </div>
  );
};

export default VideoIntro;
