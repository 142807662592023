import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import PostService from "../services/Post";
import { postModelIncoming, postModelOutcoming } from "../models/post";
import { async } from "q";
const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  status: false,
  posts: [],
  error: "",
  loading: true,
  postDetail: "",
};

export const getposts = createAsyncThunk(
  "post/getposts",
  async ({ offset, limit, type }) => {
    if (type === "all") {
      type = "";
    }
    try {
      const data = await PostService.getposts({ offset, limit, type });
      //  var posts = [];
      // data.posts.map((p) => posts.push(postModelIncoming(p)));
      return data;
    } catch (error) {
      return { error };
    }
  }
);

export const createPost = createAsyncThunk(
  "post/createpost",
  async ({
    title,
    description,
    type,
    postAddress,
    anonymously,
    media,
    url,
    gif,
    privacy,
    externalVideoUrl,
    isFound,
    initialQuestion,
    newTopicName,
    reporterLocation,
    needsCaption,
    speechLanguages,
  }) => {
    console.log(title);
    console.log(description);
    console.log(type);
    console.log(postAddress);
    console.log(anonymously);
    console.log(media);
    var country = "";
    console.log(
      "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
    );
    try {
      const adjustedPost = postModelOutcoming(
        title,
        description,
        type,
        postAddress,
        anonymously,
        media,
        url,
        gif,
        privacy,
        externalVideoUrl,
        isFound,
        initialQuestion,
        newTopicName,
        reporterLocation,
        needsCaption,
        speechLanguages
      );
      console.log("sssssssssssssssssssssssssss");
      const data = await PostService.pushPost(adjustedPost);
      console.log(data);
      return data;
    } catch (error) {
      return false;
    }
  }
);

const postSlice = createSlice({
  name: "post",
  initialState,
  extraReducers: {
    [getposts.fulfilled]: (state, action) => {
      state.status = true;
      state.posts.push(action.payload);
      state.error = null;
      state.loading = false;
    },
    [createPost.fulfilled]: (state, action) => {
      state.status = true;
      state.postDetail = action.payload;
      state.error = null;
      state.loading = false;
    },

    [getposts.rejected]: (state, action) => {
      state.status = true;
      state.posts = null;
      state.error = action.payload;
    },
  },
});

const { reducer } = postSlice;
export default reducer;
