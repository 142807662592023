import { userModelIncoming } from "./user";
import { mediaModelIncoming, mediaModelOutcoming } from "./media";
export const postType = {
  wyspp: "wyspp",
  news: "news",
  missing: "missing",
  investigative: "investigative",
  caption: "caption",
  trending_web: "trending",
  all_web: "all",
};
const getPostType = (type) => {
  if (type !== undefined && type !== null) {
    const appType = type.toLowerCase();
    switch (appType) {
      case "wyspp":
        return postType.wyspp;
      case "news":
        return postType.news;
      case "missing":
        return postType.missing;
      case "investigative":
        return postType.investigative;
      case "caption":
        return postType.caption;
      default:
        return postType.wyspp;
    }
  }
  return postType.wyspp;
};

export const postModelIncoming = (post) => {
  const author = post.owner 
  console.log(post.slug)
  return {
    // id: post.id,
    type: getPostType(post.type),
    slug: post.slug,
    title: post.title,
    discription: post.discription,
    postedAt: post.created_at,
    address: post.address === null ? "" : post.address,
    distance: post.distance === null ? "" : post.distance,
    interactions:
      post.number_interactions === undefined ? 0 : post.number_interactions,
    anonymous: author == null,
    author: author,
    trending: post.is_trending,
    media: post.media_collection,
    bookmarked: post.is_bookmarked,
  };
};

export const postModelOutcoming = (
  title,
  description,
  type,
  postAddress,
  anonymously,
  media,
  url,
  gif,
  privacy,
  externalVideoUrl,
  isFound,
  initialQuestion,
  newTopicName,
  reporterLocation,
  needsCaption,
  speechLanguages
) => {
  let post = {
    geo_location: { lat: 1.2, lng: 1.5 },
    title: title,
    description: description,
    type: type,
    is_anonymous: anonymously,
    media: getMediaCollection(media),
    gif: gif,
    privacy:privacy,
    externalVideoUrl:externalVideoUrl,
    isFound:isFound,
    initialQuestion:initialQuestion,
    newTopicName:newTopicName,
    reporterLocation:reporterLocation,
    needsCaption:needsCaption,
    speechLanguages:speechLanguages,
  };
  console.log(post);
  return post;
};

const getMediaCollection = (media) => {
  try {
    return media.map((m) => mediaModelOutcoming(m));
  } catch (error) {
    return [];
  }
};
