import React from "react";
import Logo from "../../assets/images/Logo.svg";
import LinkedIn from "../../assets/images/linked.svg";
import FaceBook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/insta.svg";
import Twitter from "../../assets/images/twitter.svg";
import { useNavigate } from "react-router-dom";

function Footer() {
  let navigate = useNavigate();
  return (
    <footer
      className="text-center text-lg-start text-white"
      style={{
        backgroundColor: "rgba(238, 241, 243, 0.1)",
      }}
    >
      <div className="container p-4 pb-0">
        <section className="">
          <div className="row ">
            <div className="col-md-4 col-lg-4 col-xl-4  mt-3">
              <img
                component="img"
                style={{
                  marginTop: "20px",

                  marginBottom: "20px",
                }}
                className="img-fluid"
                alt="logo"
                src={Logo}
              />
              <p>
                WYSPP is a Citizen Journalism Toolbox with a mission to empower
                those who want to share amazing events with the world - and
                without negative commentary or opinion
              </p>
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 mt-5 pr-5 no-padding-sm offset-md-2">
              <p>Download WYSPP</p>
              <p
                role="button"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.wyspp.appMobile&pli=1",
                    "_blank"
                  )
                }
              >
                For Android
              </p>
              <p
                role="button"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/wyspp/id1541291526",
                    "_blank"
                  )
                }
              >
                For Ios
              </p>
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 offset-md-2 mt-5 no-padding-sm">
              <p role="button" onClick={() => navigate("/termsandconditions")}>
                Terms of Service
              </p>
              <p role="button" onClick={() => navigate("/privacy")}>
                Privacy Policy
              </p>
              <p role="button" onClick={() => navigate("/help")}>
                Help & Support
              </p>
              <p role="button" onClick={() => navigate("/faqs")}>
                FAQs
              </p>
            </div>
          </div>
        </section>
        <hr></hr>
        <section className=" pt-0">
          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-8 text-center text-md-start">
              <div className="">©2022 WYSPP. All Rights Reserved</div>
            </div>
            <div className="col-md-5 col-lg-4 ml-lg-0 text-left text-md-end">
              <img
                role="button"
                component="img"
                className="footer-icon px-1"
                alt="FaceBook"
                src={FaceBook}
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/WysppOfficial",
                    "_blank"
                  )
                }
              />

              <img
                role="button"
                component="img"
                className="footer-icon px-1"
                alt="Twitter"
                src={Twitter}
                onClick={() =>
                  window.open("https://twitter.com/WysppOfficial", "_blank")
                }
              />

              <img
                role="button"
                component="img"
                className="footer-icon px-1"
                alt="Instagram"
                src={Instagram}
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/wysppofficial/",
                    "_blank"
                  )
                }
              />

              <img
                role="button"
                component="img"
                className="footer-icon px-1"
                alt="LinkedIn"
                src={LinkedIn}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/wyspp-citizen-journalism-toolbox-169b17221",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
