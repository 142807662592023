import React from "react";
import { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Sections from "./modules/app/landing/components/sections/Sections";
import Help from "./modules/app/landing/components/info/Help";
import Privacy from "./modules/app/landing/components/info/PrivacyComponent";
import TermsAndConditions from "./modules/app/landing/components/info/TermsAndConditionsComponent";
import EmailVerification from "./modules/auth/components/EmailVerification";
import ForgetPassword from "./modules/auth/components/ForgetPassword";
import PhoneVerification from "./modules/auth/components/PhoneVerification";
import ResetPassword from "./modules/auth/components/ResetPassword";
import Registrationtwo from "./modules/auth/components/Registersteptwo";
import Registration from "./modules/auth/components/Registeration";
import Login from "./modules/auth/components/Login";
import MainLayout from "./modules/app/landing/components/layout/Layout";
import AuthLayout from "./layouts/AuthLayout";
import HomeLayout from "./layouts/HomeLayout";
import Feeds from "./modules/app/posts/components/Feeds/Feeds";
import Home from "./modules/app/posts/components/post/Home";
import AddPost from "./modules/app/posts/components/post/addpost";
import ALLFAQs from "./modules/app/landing/pages/info/ALLFAQs";
import Confirmation from "./modules/auth/components/Confirmation";

function App() {
  return (
    <Fragment>
      <Routes>
        <Route element={<MainLayout />}>
          <Route exact path="/" element={<Sections />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route
            path="/termsandconditions"
            element={<TermsAndConditions />}
          ></Route>
          <Route path="/faqs" element={<ALLFAQs />}></Route>
          <Route path="/help" element={<Help />}></Route>
          <Route path="/confirmation" element={<Confirmation />}></Route>
        </Route>

        {/* <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Registration />}></Route>
          <Route path="/signup/next" element={<Registrationtwo />}></Route>
          <Route path="/forgetpassword" element={<ForgetPassword />}></Route>
          <Route path="/resetpassword" element={<ResetPassword />}></Route>
          <Route
            path="/phoneverification"
            element={<PhoneVerification />}
          ></Route>
          <Route
            path="/emailverification"
            element={<EmailVerification />}
          ></Route>

        </Route> */}

        {/* <Route element={<HomeLayout />}>
          <Route path="/feeds" element={<Feeds />}></Route>
          <Route path="/index" element={<AddPost/>}> </Route>
        </Route> */}
      </Routes>
    </Fragment>
  );
}

export default App;
