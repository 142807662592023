import React, { Fragment,useEffect} from "react";
import AllFAQsComponent from "../../components/info/AllFAQsComponent";

const ALLFAQs = () => {
  useEffect(() => {
      window.scrollTo({ top:0, left:0, behavior: "instant"});
  });
  return (
    <Fragment>
      <div className=" min-vh-100 pt-5 pb-5">
        <div className="pt-5 mt-5 pb-5 container">
          <h1 className=" static-title  ">FAQs</h1>
          <hr/>
        </div>
        <div id="terms-content" className="container">
          <AllFAQsComponent />
        </div>
      </div>
    </Fragment>
  );
};

export default ALLFAQs;
