import React, { useState, useEffect, useRef } from "react";

import HomeIphone from "../../../../../assets/images/intro-phone.png";

import Dash from "../../../../../assets/images/white-dash.svg"; // Import using relative path

const Intro = () => {
  const [loading, setLoading] = useState(true);
  const urls = [Dash];
  const counter = useRef(0);

  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= urls.length) {
      setLoading(false);
    }
  };
  return (
    <div className="bg-image smallScreenBgSize pt-5">
      <div className="container pt-4 pb-5">
        <div className="row pt-5">
          <div className="col-md-6 col-sm-12 ">
            <div className=" d-flex justify-content-start align-items-center pt-4">
            <span className="dash"></span>
              <span className="small-text">
                An entire toolbox focused on truth
              </span>
            </div>
            <div>
              <p className="very-big-text">Be a Part of Recorded History</p>
            </div>
            <div>
              <p className="big-text">
                WYSPP puts history in the hands of citizens- accurate reporting,
                unbiased, without the trolls.
              </p>
            </div>

            <div className="d-grid  d-md-block justify-content-start center-on-sm bottom-space ">
              <button
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/wyspp/id1541291526",
                    "_blank"
                  )
                }
                type="button"
                className="btn btn-primary rounded-pill mr-2 light-blue mt-2 px-3 mr-2"
                style={{ width: "200px", marginRight: "10px" }}
              >
                Download IOS App
              </button>
              <button
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.wyspp.appMobile&pli=1",
                    "_blank"
                  )
                }
                type="button"
                className="btn btn-danger rounded-pill pink mt-2 "
                style={{ width: "200px" }}
              >
                Download Android App
              </button>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 justify-content-end align-items-end d-flex center-on-sm mt-md-1">
            <div style={{ display: loading ? "block" : "none" }}>
              {/* <div className="spinner-border mx-auto" role="status"></div> */}
            </div>
            <div
              style={{ display: loading ? "none" : "block" }}
              className="justify-content-end
                align-items-end
                d-flex
                center-on-sm
            "
            >
              <img
                key={HomeIphone}
                src={HomeIphone}
                onLoad={imageLoaded}
                className="fluid img-fluid intro-img"
                
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
