import * as React from "react";
import Accordion from "react-bootstrap/Accordion";

import TitleIcon from "../../../../../assets/images/title-icon.svg"; // Import using relative path

export default function FAQ() {
  var accordionArray = [
    {
      id: 1,
      title: "What is Watch?",
      details:
        "WYSPP is a way to put journalism in your hands, the citizen’s hands. Whether it be an event that impacts you and others locally, or globally, WYSPP is the platform to share it on. It’s your time to be a part of recorded history and to acknowledge its accuracy, increase or decrease its visibility, and act socially responsible.",
    },
    {
      id: 2,
      title: "What is Connect?",
      details:
        "Connect is your chance to find answers and come to a conclusion with your community and other WYSPP users across the globe. Translate cryptic or foreign text, identify unknown objects, and more",
    },
    {
      id: 3,
      title: "What is Missing?",
      details:
        "Think of “Missing” as a hyper-localized lost and found for anything from items to pets to missing persons. It goes beyond your neighborhood, to any WYSPP user within a 50-mile radius. Avoiding the noise social media and other outlets inevitably encounter.",
    },
    {
      id: 4,
      title: "Is my mobile device supported?",
      details: "The WYSPP mobile app is supported on the following platforms",
    },
  ];
  return (
    <div
      className="container mt-5 "
      style={{
        marginBottom: "50px",
        
      }}
    >
      <div className=" d-flex justify-content-start align-items-center">
        <img
          src={TitleIcon}
          alt=" SVG"
          style={{
            marginRight: "5px",
            width: "35px",
          }}
        />
        <span className="title pb-2">FAQs</span>
      </div>

      <p className="pt-2 pb-2">
        Answers to the most common questions we get about the WYSPP app:
      </p>
      <Accordion defaultActiveKey="0" style={{paddingLeft: "5%",}}>
        {accordionArray.map((d) => (
          <Accordion.Item
            key={d.id}
            style={{
              marginTop: "5px",
              backgroundColor: "rgba(238, 241, 243, 0.1)",
              borderRadius: " 8px",
              border: "none",
            }}
            eventKey={d.id}
          >
            <Accordion.Header
              style={{
                //backgroundColor: "rgba(238, 241, 243, 0.1)",
                //color: "rgba(238, 241, 243, 0.1)",
                fontWeight: "400",
                fontSize: "25px",
                border: "none",
                borderRadius: " 8px",
              }}
            >
              {d.title}
            </Accordion.Header>
            <Accordion.Body
              style={{
                color: "white",
                fontWeight: "400",
                fontSize: "18px",
              }}
            >
              {d.details}
              {d.id === 4 && (
                <ul>
                  <li>
                    iOS 11.0 or later (iPhone, iPod touch, and/or iPad) 
                  </li>
                  <li>and Android 5.x.x or later</li>
                </ul>
              )}

            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <div class="d-flex flex-row-reverse bd-highlight mt-2">

      <a href="/faqs" className="text-end text-info text-decoration-underline">See All</a>

      </div>
    </div>
  );
}
