import React from "react";
import VideoIntro from "./Video";
import Intro from "./Intro";
import About from "./About";
import FAQ from "./FAQ";

const sections = () => {
  return (
    <div>
      <Intro />
      <VideoIntro />
      <About />
      <FAQ />
    </div>
  );
};

export default sections;
